import React from 'react'
import MainLayout from '../layout/mainLayout';
import Head from '../components/utils/head'

const Contact = () => {
  return (
    <MainLayout>
    <Head />
    </MainLayout>
  )
}

export default Contact
